import './App.css';
import {Link} from 'react-router-dom'

function App() {
  return (
    <main className="container-fluid linktree">
      <img className="z-1 position-absolute end-0 col-6 col-md-3" src="./img/sun.webp" alt="soleil" />
      <div className="row px-3 vh-100 align-items-center">
        <div className="col-md-6 col-lg-5 col-xl-4 mx-auto justify-content-center glass">
            <h1 className="my-5">Always <br/> a Kid</h1>
            <a href="https://www.instagram.com/alwaysakid.shop/" target="_blank" rel="noreferrer" className={"d-block w-75 my-4 fs-3 fw-medium mx-auto btn btn-primary text-center"}>Instagram</a>
            <a href="https://www.tiktok.com/@alwaysakid.shop" target="_blank" rel="noreferrer" className={"d-block w-75 my-4 fs-3 fw-medium mx-auto btn btn-primary text-center"}>TikTok</a>
            <a href="https://www.linkedin.com/company/alwaysakid/" target="_blank" rel="noreferrer" className={"d-block w-75 my-4 fs-3 fw-medium mx-auto btn btn-primary text-center"}>Linkedin</a>
            <span className={"d-block w-75 my-4 fs-3 fw-medium mx-auto btn btn-primary disabled text-center"}>Site web - reconstruction</span>
        </div>
        <img alt="herbe" class="w-100 fixed-bottom grass p-0 m-0" src="./img/grass.webp"  />
      </div>
    </main>
  );
}

export default App;
